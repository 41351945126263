<template>
  <tr :class="{ tr_select: isSelected }">
    <!-- 체크 -->
    <td>
      <CheckboxItem :id="rowData" :isDisabled="!$isAdmin" :checkedNames.sync="checkedNamesSync" />
    </td>
    <!-- 발주상태 -->
    <td>
      <!-- <span :class="['badge_state', stateType]">{{ rowData.statusName || '' }}</span> -->
      {{ rowData.statusName }}
    </td>
    <!-- <td>발주차수</td> -->
    <td>{{ rowData.orderCid }}</td>
    <!-- 발주서명 -->
    <td class="td_ellip align_left" :data-ellip="rowData.title">
      <router-link
        :to="toPath"
        class="link_subject"
        :data-ellip="rowData.title"
        v-html="rowData.title"
      />
    </td>
    <!-- 프로젝트코드	 -->
    <td>
      {{ rowData.projectCode }}
    </td>
    <!-- 프로젝트명 -->
    <td
      class="td_ellip align_left"
      :data-ellip="`[${rowData.projectCode}] ${rowData.projectName}`"
      v-html="rowData.projectName"
    ></td>
    <!-- 파트너사 -->
    <td class="td_ellip align_left">
      <button
        class="link_subject"
        :data-ellip="rowData.companyName || ''"
        @click="$windowOpen(`${$routerPath.PARTNER_VIEW}/${rowData.userNum}`)"
      >
        {{ rowData.companyName || "" }}
      </button>
    </td>
    <!-- 계약시작일 -->
    <td>
      {{ rowData.orderStartDate | dateStringFormat }}
    </td>
    <!-- 계약종료일 -->
    <td>
      {{ rowData.orderEndDate | dateStringFormat }}
    </td>
    <!-- 발주금액 -->
    <td class="align_right">{{ rowData.price | currency }}</td>
    <!-- 발주일 -->
    <td>
      {{ rowData.orderDate | dateStringFormat }}
    </td>
    <!-- 발주동의일 -->
    <td>
      {{ rowData.agreeDate | dateStringFormat }}
    </td>
    <!-- 품목코드 -->
    <td>
      <button
        class="link_subject"
        @click="$windowOpen(`${$routerPath.ITEM_VIEW}/${rowData.itemCode}`)"
      >
        {{ rowData.displayCode }}
      </button>
    </td>
    <!-- 품목명 -->
    <td class="td_ellip align_left" :data-ellip="rowData.itemName">
      {{ rowData.itemName || "" }}
    </td>
    <!-- 연결상태 -->
    <td>
      <button
        v-if="rowData.estimateNum"
        :data-ellip="rowData.contractName || ''"
        :class="['link_subject', 'align_center']"
        @click="$windowOpen(`${$routerPath.ESTIMATE_VIEW}/${rowData.estimateNum}`)"
      >
        견적 - {{ rowData.estimateNum }}
      </button>
      <button
        v-if="false"
        :data-ellip="rowData.contractName || ''"
        :class="['link_subject', 'align_center']"
        @click="$windowOpen(`${$routerPath.ANNOUNCE_VIEW}/???`)"
      >
        공고 - {{ "rowData.???" }}
      </button>
      <button
        v-if="rowData.contractId"
        :data-ellip="rowData.contractName || ''"
        :class="['link_subject', 'align_center']"
        @click="$windowOpen(`${$routerPath.CONTRACT_VIEW}/${rowData.contractId}`)"
      >
        계약 - {{ rowData.contractCid }}
      </button>
      <!-- 전부다 없을떄 -->
      <template v-if="!rowData.estimateNum && !rowData.contractId"> - </template>
    </td>
    <!--	검수담당자	-->
    <td>{{ rowData.inspecterName }} ({{ rowData.inspecterLoginId }})</td>
    <!-- 구매담당자 -->
    <td>{{ rowData.name }} ({{ rowData.loginId }})</td>
    <!-- 전자결재 -->
    <td>
      <template v-if="rowData.docStatus">
        <button
          :class="['link_subject', 'align_center']"
          @click="$windowOpen(`${computedPaymentPath}/${rowData.docNo}`)"
        >
          {{ rowData.docStatusName }}
        </button>
        <!-- <button
          v-if="isMyPayment"
          :class="['link_subject', 'align_center']"
          @click="$windowOpen(`${computedPaymentPath}/${rowData.docNo}`)"
        >
          {{ rowData.docStatusName }}
        </button>
        <button v-else :class="['align_center']" @click="onClickPaymentReject">
          {{ rowData.docStatusName }}
        </button> -->
      </template>
      <template v-else>
        {{ "-" }}
      </template>
    </td>
  </tr>
</template>

<script>
import LocalStorageManager from "@/LocalStorageManager";
import CheckboxItem from "@/components/common/checkbox/CheckboxItem";

import ListLineMixin from "@/mixins/ListLineMixin";
import { getPaymentRouterPath } from "@/utils/paymentPathUtils";
import { getDateString } from "@/utils/dateUtils";
import { makePathWithQuery } from "@/utils/urlUtils";

import { ALERT_ACTION } from "@/store/modules/alert/action";

export default {
  name: "OrderListLine",
  components: {
    CheckboxItem,
  },
  mixins: [ListLineMixin],
  props: {
    checkedNames: Array,
    rowData: Object,
  },
  computed: {
    toPath() {
      const { orderCid, status } = this.rowData;

      const isModify = status === "T";

      // const routerPath = isModify ? this.$routerPath.ORDER_MODIFY : this.$routerPath.ORDER_VIEW;
      const routerPath = this.$routerPath.ORDER_VIEW;

      const path = makePathWithQuery(routerPath, orderCid, this.$route.query);
      return path;
    },
    toPostCidPath() {
      const path = `${this.$routerPath.ANNOUNCE_VIEW}/${this.rowData.postCid}`;
      return path;
    },
    stateType() {
      switch (this.rowData.status) {
        case "G":
          return "on";
        case "C":
          return "on";
        case "T":
          return "";
        case "E":
          return "disabled";
      }
      return "";
    },

    isMyPayment() {
      const { username } = LocalStorageManager.shared.getUserData();
      const { loginId } = this.rowData;
      return username === loginId;
    },
    computedPaymentPath() {
      const { docStatus } = this.rowData;
      const ret = getPaymentRouterPath(docStatus);
      return ret;
    },
    isSelected() {
      const ret = this.checkedNames.find(
        (item) => String(item.orderCid) === String(this.rowData.orderCid),
      );

      return !!ret;
    },
    checkedNamesSync: {
      get() {
        return this.checkedNames;
      },
      set(value) {
        this.$emit("update:checkedNames", value);
      },
    },
  },
  methods: {
    onClickPaymentReject() {
      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT, {
        text: "해당 기안의 열람 권한이 없습니다.",
      });
    },
  },
};
</script>
