<template>
  <FilterBarBase
    :excelBtn="$isAdmin ? true : false"
    @onClickSearch="onClickSearch"
    @onClickExcelBtn="onClickExcelBtn"
    @onClickReset="onClickReset"
  >
    <dl class="w152">
      <dt>발주 상태</dt>
      <dd>
        <SelectBox :dataList="statusList" :isDisabled="isDisabledStatus" :value.sync="stateId" />
      </dd>
      <dt>내 문서</dt>
      <dd>
        <div class="group_form">
          <CheckboxOnlyOne id="1" :isSelected.sync="isMyWork" text="내 문서만 보기" />
        </div>
      </dd>
    </dl>
    <dl>
      <dt>발주일</dt>
      <dd>
        <DateRangeQuickSelect :dates.sync="dates" />
      </dd>
      <!-- <dt>분류</dt>
      <dd>
        <CategoryGroupIncludeData
          :category0.sync="category0"
          :category1.sync="category1"
          :category2.sync="category2"
        />
      </dd> -->
      <dt>검색어</dt>
      <dd>
        <div class="group_form">
          <SelectBox class="w208" :dataList="keywordDatas" :value.sync="keywordSelectedId" />
          <Input class="w425" :value.sync="keywordInputText" @onEnterKey="onClickSearch" />
        </div>
      </dd>
    </dl>
  </FilterBarBase>
</template>

<script>
import FilterBarBase from "@/components/shared/filterBar/FilterBarBase";
import Input from "@/components/common/input/Input";
import Radio from "@/components/common/radio/Radio";
import SelectBox from "@/components/common/selectBox/SelectBox";
import CategoryGroupIncludeData from "@/components/common/selectBox/CategoryGroupIncludeData";
import CheckboxOnlyOne from "@/components/common/checkbox/CheckboxOnlyOne";
import DateRangeQuickSelect from "@/components/common/calendar/DateRangeQuickSelect";

import FilterBarMixin from "@/mixins/FilterBarMixin";

export default {
  components: {
    FilterBarBase,
    Input,
    Radio,
    SelectBox,
    DateRangeQuickSelect,
    CategoryGroupIncludeData,
    CheckboxOnlyOne,
  },
  mixins: [FilterBarMixin],
  props: {
    defaultIsMyWork: Boolean,
  },
  data() {
    return {
      isMyWork: this.defaultIsMyWork,
    };
  },
  methods: {
    onClickSearch() {
      let obj = this.getCommonFilterbarData();
      obj.isMyWork = this.isMyWork;

      this.$emit("onClickSearch", obj);
    },
    onClickReset() {
      this.isMyWork = false;
      this.onClickResetFilterBar();
      this.stateId = "0";
    },
    onClickExcelBtn() {
      const isDatesSelected = this.dates && this.dates.length === 2;

      this.$emit("onClickExcelBtn", isDatesSelected);
    },
  },
};
</script>
