<template>
  <PageWithLayout>
    <div class="section_comm">
      <div class="head_section">
        <h2 id="kakaoBody" class="tit_section">발주관리</h2>
      </div>
      <div class="body_section">
        <!-- ref: 엑셀 다운로드에서 사용.. -->
        <OrderListFilterBar
          ref="filterBar"
          :statusList="statusList"
          :keywordDatas="keywordList"
          :defaultStateId="defaultStateId"
          :defaultKeywordSelectedId="defaultKeywordSelectedId"
          :defaultKeywordInputText="defaultKeywordInputText"
          :defaultDates="defaultDates"
          :defaultIsMyWork="defaultIsMyWork"
          @onClickSearch="onClickSearch"
          @onClickExcelBtn="onClickExcelBtn"
        />

        <TableHead>
          <template v-slot:headLeft>
            <button
              v-if="getCanNewWriteProcess"
              type="button"
              class="btn_tertiary btn_medium"
              @click="onClickGoToWrite"
            >
              <span class="ico_purchase ico_cross_w" />발주 등록
            </button>
          </template>
          <template v-slot:headRight>
            <div class="group_form">
              <template v-if="$isAdmin">
                <button
                  class="btn_tertiary btn_medium"
                  :disabled="computedCheckedList.length === 0"
                  @click="onClickSendAlarm"
                >
                  <span class="material-icons-outlined send-alarm"> forward_to_inbox </span>발주서명
                  요청
                  <strong>{{ `(${computedCheckedList.length})` }}</strong>
                </button>
                <span class="bar_view"> </span>
              </template>
              <SelectBoxPageRange
                :currentMaxRowCount.sync="size"
                @update:currentMaxRowCount="onChangeRowCount"
              />
            </div>
          </template>
        </TableHead>
        <ListPage
          :list="dataList"
          :isHorizontalScroll="true"
          :scrollWidth="'2100px'"
          :currentPageIndex="currentPageIndex"
          :totalPageCount="totalPageCount"
          :maxRowCount="size"
          :maxPaginationCount="maxPaginationCount"
          @onChangePage="onChangePage"
        >
          <template v-slot:colgroup>
            <!-- 체크박스 -->
            <col style="width: 40px" />
            <!-- 발주상태 -->
            <col style="width: 100px" />
            <!-- 발주차수 -->
            <!-- <col style="width: 60px" /> -->
            <!-- 발주번호 -->
            <col style="width: 160px" />
            <!-- 발주서명 -->
            <col />
            <!-- 프로젝트코드 -->
            <col style="width: 160px" />
            <!-- 프로젝트명 -->
            <col style="width: 180px" />
            <!-- 파트너사 -->
            <col style="width: 100px" />
            <!-- 계약시작일 -->
            <col style="width: 80px" />
            <!-- 계약종료일 -->
            <col style="width: 80px" />
            <!-- 발주금액 -->
            <col style="width: 120px" />
            <!-- 발주일 -->
            <col style="width: 80px" />
            <!-- 발주동의일 -->
            <col style="width: 80px" />
            <!-- 품목코드 -->
            <col style="width: 120px" />
            <!-- 품목명 -->
            <col style="width: 160px" />
            <!-- 연결상태 -->
            <col style="width: 80px" />
            <!-- 검수담당자 -->
            <col style="width: 160px" />
            <!-- 구매담당자 -->
            <col style="width: 160px" />
            <!-- 전자결재 (진행,완료,반려) -->
            <col style="width: 60px" />
          </template>
          <template v-slot:tr>
            <th>
              <CheckboxItem
                ref="checkAll"
                :isDisabled="!$isAdmin"
                @update:checkedNames="onClickedAll"
              />
            </th>
            <th>발주상태</th>
            <!-- <th>발주차수</th> -->
            <th>발주번호</th>
            <th>발주서명</th>
            <th>프로젝트코드</th>
            <th>프로젝트명</th>
            <th>파트너사</th>
            <th>계약시작일</th>
            <th>계약종료일</th>
            <th>발주금액</th>
            <th>발주일</th>
            <th>발주동의일</th>
            <th>품목코드</th>
            <th>품목명</th>
            <th>연결상태</th>
            <!-- <th  :data-ellip="'견적,공고,계약'">연결상태</th>-->
            <th>검수담당자</th>
            <th>구매담당자</th>
            <th>전자결재</th>
          </template>
          <template v-slot:row="slotProps">
            <OrderListLine :rowData="slotProps.rowData" :checkedNames.sync="checkedNames" />
          </template>
          <template v-slot:emptyList>
            <tr>
              <td colspan="17" class="td_empty">발주가 없습니다</td>
            </tr>
          </template>
        </ListPage>
      </div>
    </div>
    <template v-slot:popup>
      <AlertPopup
        v-if="isPopAlert"
        alertText="기간을 설정하지 않으셨습니다.<br>모든 데이터를 다운로드 하시겠습니까?"
        btnFourthlyText="취소"
        btnPrimaryText="확인"
        @onClickFourtyly="onClickCancelAlert"
        @onClickPrimary="onClickConfirmAlert"
      />
      <ExcelDonwloadPopup
        v-if="isPopExcel"
        :excelDataList="excelHeaderList"
        :checkedNames.sync="checkedNamesExcel"
        @onClickClose="onClickCloseExcel"
        @onClickDownload="onClickDownloadExcel"
      />
    </template>
  </PageWithLayout>
</template>

<script>
import PageWithLayout from "@/components/layout/PageWithLayout";
import ListPage from "@/components/shared/ListPage";
import OrderListFilterBar from "@/components/admin/order/list/OrderListFilterBar";
import OrderListLine from "@/components/admin/order/list/OrderListLine";
import TableHead from "@/components/shared/TableHead";
import SelectBoxPageRange from "@/components/common/selectBox/SelectBoxPageRange";
import CheckboxItem from "@/components/common/checkbox/CheckboxItem";
import AlertPopup from "@/components/layout/popup/AlertPopup";
import ExcelDonwloadPopup from "@/components/layout/popup/ExcelDonwloadPopup";

import ApiService from "@/services/ApiService";
import LocalStorageManager from "@/LocalStorageManager";

import { dateToString, stringToDate } from "@/utils/dateUtils";
import excelKey from "@/constants/excelKey";

import { mapState } from "vuex";
import { ORDER_LIST_ACTION } from "@/store/modules/order/action";
import { ALERT_ACTION } from "@/store/modules/alert/action";

import { PROGRESS_START_ACTION, PROGRESS_END_ACTION } from "@/store/modules/progressbar/action";
import { downloadFile } from "@/utils/fileUtils";
import { makeQueryStringByObject } from "@/utils/urlUtils";
import PageMixin from "@/mixins/PageMixin";
import ListPageMixin from "@/mixins/ListPageMixin";

export default {
  components: {
    PageWithLayout,
    OrderListFilterBar,
    TableHead,
    CheckboxItem,
    SelectBoxPageRange,
    ListPage,
    OrderListLine,
    AlertPopup,
    ExcelDonwloadPopup,
  },
  mixins: [PageMixin, ListPageMixin],
  data() {
    return {
      API_PATH: this.$apiPath.ORDER,
      defaultStateId: "0",
      defaultKeywordSelectedId: "projectName",
      defaultIsMyWork: false,
      excelKey: excelKey.ORDER,
      checkedNames: [],
    };
  },

  computed: {
    ...mapState({
      statusList: (state) => state.order.statusList,
      keywordList: (state) => state.order.keywordList,
    }),
    getCanNewWriteProcess() {
      if (!this.isShowAccessBtn(this.menuId.ORDER_WRITE)) return false;
      return LocalStorageManager.shared.getCanNewWriteProcess();
    },

    computedOrderStaus() {
      return this.$route.query.status;
    },
    computedCheckedList() {
      //여기서 전달데이터 가공
      const ret = this.checkedNames.map((item) => {
        return item.orderCid;
      });
      return ret;
    },
  },
  watch: {
    checkedNames(newVal, oldVal) {
      if (this.dataList.length === 0 || this.dataList.length !== newVal.length) {
        this.$refs.checkAll.forceUnChecked();
      } else {
        this.$refs.checkAll.forceChecked();
      }
    },
  },
  created() {
    const params = this.checkUrlQuery();

    this.$store.dispatch(ORDER_LIST_ACTION).then(() => {
      this.getDataInit(params);
    });
  },
  methods: {
    // 주소 기준으로 현재 페이지에 필요한 params 가져온다.
    // 주소 기준으로 필터바 초기값을 셋팅한다.
    checkUrlQuery() {
      const { query } = this.$route;

      const objPaging = this.checkQueryPaging(query);

      const objKeyword = this.checkQueryKeywords(query, this.keywordList);

      const objDates = this.checkQueryDates(query, "orderDate1", "orderDate2");

      const objStatus = this.checkQueryStatus(query);

      const objCateNum = this.checkQueryCateNum(query);

      const ret = Object.assign({}, objPaging, objKeyword, objDates, objStatus, objCateNum);
      const { isMyWork } = query;
      if (isMyWork) {
        this.defaultIsMyWork = isMyWork === "Y";
        ret.isMyWork = isMyWork;
      }
      return ret;
    },
    getFilterbarParams(objData) {
      const {
        keywordSelectedId,
        keywordInputText,
        startDt,
        endDt,
        status,
        category0,
        category1,
        category2,
        isMyWork,
      } = objData;

      let params = {};

      if (keywordSelectedId && keywordInputText) {
        params[keywordSelectedId] = keywordInputText;
      }

      if (startDt && endDt) {
        params["orderDate1"] = startDt;
        params["orderDate2"] = endDt;
      }

      if (status) {
        params.status = status;
      }

      const cateNum = category2 || category1 || category0;

      if (cateNum) {
        params.cateNum = cateNum;
      }
      params.isMyWork = isMyWork ? "Y" : "N";
      return params;
    },
    async onClickGoToWrite() {
      const result = await ApiService.shared.getData(this.$apiPath.CREATE_ORDER_NUMBER);
      if (!result.data || !result.data.orderCid) {
        this.alert(result.text);
        return;
      }

      const newNumber = result.data.orderCid;

      this.$router.push({
        path: `${this.$routerPath.ORDER_WRITE}/${newNumber}`,
      });
    },
    onClickedAll(checked) {
      if (checked) {
        this.checkedNames = this.dataList;
      } else {
        this.checkedNames = [];
      }
    },
    onClickSendAlarm() {
      const params = {
        text: `총 ${this.computedCheckedList.length}건의 발주서명요청 알림을 발송 하시겠습니까?`,
        onClickY: () => {
          this.sendAlarm();
        },
      };
      this.$store.dispatch(ALERT_ACTION.SHOW_ALERT_YN, params);
    },
    async sendAlarm() {
      const obj = {};
      obj.manualType = "ORDER_PUBLISH";
      obj.targetPk = this.computedCheckedList;
      const result = await ApiService.shared.post(`${this.$apiPath.ALRIM}/sendManual`, obj);

      const { code, data, text } = result;
      if (code !== "200") {
        this.alert(text);
        return;
      }
      this.alert(`총 ${this.computedCheckedList.length}건 발송되었습니다.`);
      const params = this.checkUrlQuery();

      this.$store.dispatch(ORDER_LIST_ACTION).then(() => {
        this.getDataInit(params);
      });
      this.checkedNames = [];
    },
    async onClickExcelBtn() {
      this.$store.dispatch(PROGRESS_START_ACTION);
      const path = `${this.$apiPath.EXCEL}/order`;
      const filterBar = this.$refs.filterBar;
      if (!filterBar) return;
      const objFilterBarData = filterBar.getCommonFilterbarData();
      const params = this.getFilterbarParams(objFilterBarData, true);
      const result = await ApiService.shared.get(`${path}${makeQueryStringByObject(params)}`, {
        isNeedAuth: true,
        responseType: "arraybuffer",
      });
      this.$store.dispatch(PROGRESS_END_ACTION);
      downloadFile(result, `발주관리_${this.moment().format("YYYYMMDD")}.xlsx`);
    },
  },
};
</script>
<style lang="scss" scoped>
.send-alarm {
  margin-right: 8px;
  font-size: 16px;
  vertical-align: middle;
}
</style>
